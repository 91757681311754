import React from 'react';
import { PropTypes } from 'prop-types';
import { CommonContext } from '../../context';
import Layout from '../../components/layout';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const query = graphql`
  {
    bkashImage: file(relativePath: { eq: "bkash.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: NONE
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    nagadImage: file(relativePath: { eq: "nagad.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: NONE
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
  }
`;

const DonateFromBDPage = ({ location }) => {
  const data = useStaticQuery(query);
  const bkashImage = data.bkashImage.childImageSharp.gatsbyImageData;
  const nagadImage = data.nagadImage.childImageSharp.gatsbyImageData;

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title="Support From Bangladesh" location={location} noindex>
        <section>
          <div className="flex justify-center">
            <div className="w-full max-w-6xl px-6 md:px-0">
              <div className="flex justify-center mt-0 mb-24 md:mt-8">
                <div className="w-full max-w-3xl p-6 blog markdown md:p-0">
                  <h1>বাংলাদেশ থেকেই এখন GreenTech Apps কে সাপোর্ট করুন </h1>
                  <p>আসসালামু আলাইকুম,</p>
                  <p>
                    প্রিয় GTAF পরিবার, আলহামদুলিল্লাহ দীর্ঘ ৯ বছর ধরে আপনাদের
                    অপরিসীম সমর্থন পেয়ে আমরা আন্তরিকভাবে কৃতজ্ঞ।
                  </p>
                  <p>
                    এখন থেকে দেশি বিভিন্ন পেমেন্ট সিস্টেম ব্যবহার করেই আপনারা
                    আর্থিক অনুদান পাঠাতে পারবেন যা সরাসরি Greentech Apps
                    Foundation এর কুরআন, হাদিস, হিসনুল মুসলিম সহ অন্যান্য
                    এপগুলোর উন্নয়ন, নতুন নতুন ফিচার সংযোজনের কাজে ব্যবহার করা
                    হবে ইন শা আল্লাহ।
                  </p>
                  <p>
                    আপনারা অনেকেই নিয়মিত অনুদান পাঠানোর জন্য আমাদের কাছে আগ্রহ
                    প্রকাশ করেছেন, যেটা এখন থেকে নিচের অপশনগুলোর মাধ্যমে সহজেই
                    আপনারা করতে পারবেন।
                  </p>
                  <p>১। আল আরাফা ইসলামি ব্যাংক</p>
                  <p>
                    Al-Arafah Islami Bank Limited.
                    <br />
                    Account No: 1171020010205
                    <br />
                    Account Name: Greentech Apps Bangladesh
                    <br />
                    Branch Name: Kawran Bazar Branch
                    <br />
                    SWIFT Code: ALARBDDH
                    <br />
                    Routing No: 015262538
                  </p>
                  <p>
                    ২। নগদের মাধ্যমে “মার্চেন্ট পে” অপশন থেকে নিচের মার্চেন্ট
                    একাউন্টে অনুদান পাঠাতে পারবেন।
                  </p>
                  <p className="font-bold">
                    নগদ মার্চেন্ট একাউন্ট নাম্বারঃ 01914353693{' '}
                  </p>
                  <p className="font-bold">
                    [রকেট এবং বিকাশ নাম্বার থেকে একটি ডিজিট ভিন্ন]
                  </p>
                  <GatsbyImage
                    image={nagadImage}
                    className="mx-auto text-center fill-current"
                    alt="bkash greentech"
                  />
                  <p>
                    ৩।{' '}
                    <span>
                      রকেটের মাধ্যমে “মার্চেন্ট পে” অপশন থেকে নিচের মার্চেন্ট
                      একাউন্টে অনুদান পাঠাতে পারবেন। রেফারেন্স নাম্বার দেয়ার
                      প্রয়োজন নেই, চাইলে 1 দিতে পারেন।
                    </span>
                  </p>
                  <p className="font-bold">
                    রকেট মার্চেন্ট একাউন্ট নাম্বারঃ 01914353593
                  </p>
                  <p>
                    ৪।{' '}
                    <span className="font-bold">
                      সংগত কারণে আমরা বিকাশ ব্যবহার অনুৎসাহিত করছি, তারপরও এমন
                      কেউ যদি থাকেন যার বিকাশ ছাড়া অন্য অপশন নেই, তাহলে বিকাশে
                      পাঠাতে পারেন।
                    </span>
                  </p>
                  <p>
                    বিকাশের মাধ্যমে পেমেন্ট অপশন থেকে নিচের মার্চেন্ট একাউন্টে
                    অনুদান পাঠাতে পারবেন।
                  </p>
                  <p className="font-bold">
                    বিকাশ মার্চেন্ট একাউন্ট নাম্বারঃ 01914353593
                  </p>
                  <GatsbyImage
                    image={bkashImage}
                    className="mx-auto text-center fill-current"
                    alt="bkash greentech"
                  />
                  <p>
                    আপনার যেকোনো পরিমাণ অনুদান আমাদের মূল লক্ষ্য এবং উদ্দেশ্য
                    অর্জনে সহায়তা করবে ইনশাআল্লাহ।
                  </p>
                  <p className="font-bold">
                    বিঃদ্রঃ অনুগ্রহ করে কেউ যাকাত পাঠাবেন না।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </CommonContext.Provider>
  );
};

DonateFromBDPage.propTypes = {
  location: PropTypes.object,
};

export default DonateFromBDPage;
